import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
var date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();
export const vehicleCounterSalesSlice = createSlice({
  name: "counterSales",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    activeTab: "1",
    mcApplicableListCount: 0,
    mcApplicableList: [],
    vehiclesList: [],
    purchaseHistoryList: [],
    saleHistoryList: [],
    saleHistoryCount: 0,
    OVFQuotationPendingList: [],
    vehicleSaleData: {
      hypothecationisinternal: false,
      loanstatus: false,
    },
    salesQuotationData: {},
    addSaleData: {
      date: moment(),
      hypothecationisinternal: false,
      loanstatus: false,
      // ovfdetails:{
      //   totalRepairAmountType:""
      // }

    },
    minSaleAmount: 0,
    maxSaleAmount: 0,
    minDownPaymentAmount: 0,
    maxFinanceAmount: 0,
    emiDifferenceDays: 0,
    imageLooading: false,
    vehicleVersion: {},
    counterData: {},
    checkApprove: false,
    requestVerification: false,
    RequestMode: false,
    salesRejectData: {},
    salesRequestData: {},
    customerByIdList: {},
    params: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    vehicleSaleParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    mcApplicableParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    OVFQuotationPendingParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    tenuresObjData: {},
    fVRequestData: [],
    viewModel: false,
    fVRequestModelData: {},
    minSaleAmountObj: {},
    tenuresList: [],
    miniEMIdatesList: [],
    rejectReasonObjData: {},
    maxOVFDPDUEAmount: 0,
    submitLoading: false,
    vehicleHistoryCount: 0,
    vehicleHistLoading: false,
  },
  reducers: {
    getVehiclesList: (state, action) => {
      if (state.vehicleSaleParams?.page == 1) {
        return {
          ...state,
          loading: true,
          vehiclesList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    vehicleListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.vehicleSaleParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        vehiclesList: action.payload.response.results,
        loading: false,
        vehicleSaleParams: {
          ...state.vehicleSaleParams,
          no_of_pages: noofpages,
        },
      };
    },
    setVehicleSaleParams: (state, action) => {
      return {
        ...state,
        vehicleSaleParams: action.payload,
      };
    },
    clearVehiclesList: (state) => {
      return {
        ...state,
        loading: true,
        vehiclesList: [],
        vehicleSaleParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    vehicleSaleActiveTab: (state, action) => {
      return {
        ...state,
        loading: true,
        activeTab: action.payload,
      };
    },
    getPurchaseHistory: (state, action) => {
      return {
        ...state,
        vehicleHistLoading: true,
        purchaseHistoryList: [],
      };
    },
    purchaseHistoryListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.params.page_size
      );
      return {
        ...state,
        vehicleHistoryCount: action.payload.response.count,
        purchaseHistoryList: action.payload.response.results,
        vehicleHistLoading: false,
        params: {
          ...state.params,
          no_of_pages: noofpages,
        },
      };
    },
    getSaleHistory: (state, action) => {
      return {
        ...state,
        loading: true,
        saleHistoryList: [],
      };
    },

    saleHistoryListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.params.page_size
      );

      return {
        ...state,
        saleHistoryCount: action.payload.response.count,
        saleHistoryList: action.payload.response.results,
        loading: false,
        params: {
          ...state.params,
          no_of_pages: noofpages,
        },
      };
    },

    addSale: (state, action) => {
      return {
        ...state,
        // loading: false,
        submitLoading: true,
      };
    },

    getVehicleSaleDoc: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleSaleData: {},
        addSaleData: {},
        vehicleVersion: {},
      };
    },

    vehicleSaleDocSuccessful: (state, action) => {
      console.log("vehicleSaleDocSuccessful", action.payload);
      var tenuresObj = {};
      if (action.payload.financesale != null) {
        state.tenuresList.map((tenure) => {
          if (tenure.name == action.payload.financesale.tenures) {
            tenuresObj = tenure;
          }
        });
      }
      return {
        ...state,
        vehicleSaleData: action.payload,
        vehicleVersion: action.payload.vehicle?.version,
        tenuresObjData: tenuresObj,
        addSaleData: {
          ...action.payload,
          date: moment(action.payload.date),
          purchaserate:
            action.payload.id == 0 ? "" : action.payload.purchase?.purchaserate,
          counter_id: action.payload.counter?.id,
          salequotation_id: action.payload.salequotation?.id,
          customer_id: action.payload.customer?.id,
          addressproof1_id: action.payload.addressproof1?.id,
          addressproof2_id: action.payload.addressproof2?.id,
          externalmcbank_id: action.payload.externalmcbank?.id,
          vehicle_id: action.payload.vehicle?.id,
          purchase_code: action.payload.purchase?.code,
          purchase_id: action.payload.purchase?.id,
          vehicle_no: action.payload.vehicle?.vehicleno,
          mc_internal_type:
            action.payload.mc_internal_type == null
              ? 3
              : action.payload.mc_internal_type,
          ovfquotation_id: action.payload.ovfquotation?.id,
          financesale: {
            ...action.payload.financesale,
            guarantor_id: action.payload.financesale?.guarantor?.id,
            interestpercentage_id:
              action.payload.financesale !== null
                ? action.payload.financesale?.interestpercentage == null
                  ? ""
                  : action.payload.financesale?.interestpercentage?.id
                : "",
            emi_amount: action.payload.financesale?.emi_amount,
            totalrepaymentamount:
              action.payload.financesale?.totalrepaymentamount,
            downpayment_due_date: moment(
              action.payload.financesale?.downpayment_due_date
            ),
            emi_firstdate: moment(
              action.payload.financesale?.emi_firstdate
            ).format("DD-MM-YYYY"),
            
          },
          ovfdetails: {
            ...action.payload.ovfdetails,
            insuranceexpdate:
            action.payload.ovfdetails?.insuranceexpdate == null
              ? null
              : moment(action.payload.ovfdetails?.insuranceexpdate),
              insurancecompany_id: action.payload.ovfdetails?.insurancecompany?.id,
            // insuranceexpdate: props.addSaleData?.ovfdetails?.insuranceexpdate
            // iscustomcolor: action.payload.ovfdetails.iscustomcolor,
            // customcolor: action.payload.ovfdetails.customcolor
          },
        },
        loading: false,
        saleHistoryCount: 0,
      };
    },
    salesApproval: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getSalesCheckApprove: (state) => {
      return {
        ...state,
        loading: true,
        checkApprove: false,
      };
    },
    salesCheckApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        checkApprove: action.payload,
      };
    },
    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        salesRejectData: {},
        loading: false,
      };
    },
    rejectModelInputChangeValue: (state, action) => {
      return {
        ...state,
        salesRejectData: {
          ...state.salesRejectData,
          [action.payload.key]: action.payload.value,
        },
        loading: false,
      };
    },
    clearSalesData: (state, action) => {
      return {
        ...state,
        loading: false,
        addSaleData: {
          ...state.addSaleData,
          ...action.payload,
        },
        vehicleVersion: {},
      };
    },
    getSaleDataQuotationById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    SaleDataQuotationByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        addSaleData: {
          ...state.addSaleData,
          purchase_id: action.payload.purchase?.id,
          purchase_code: action.payload.purchase?.code,
          purchaserate:
            action.payload.purchase?.total == null
              ? 0
              : parseInt(action.payload.purchase?.total),
          vehicle_id: action.payload.purchase?.vehicle?.id,
          vehicle_no: action.payload.purchase?.vehicle?.vehicleno,
          specialincentive:
            action.payload.purchase?.specialincentive == null
              ? 0
              : parseInt(action.payload.purchase?.specialincentive),
          saleamount: action.payload.saleamount,
          // rtaamount: action.payload.rtaamount,
          // insuranceamount: action.payload.insuranceamount,
          discountamount:
            action.payload.discountamount == null
              ? 0
              : action.payload.discountamount,
          // downpaymentamount: action.payload.downpaymentamount,
        },
        vehicleVersion: action.payload.purchase?.vehicle?.version,
        minSaleAmount: parseInt(action.payload.purchase?.minimumsaleamount),
      };
    },

    getOVFSaleQuotationById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    OVFSaleQuotationByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        addSaleData: {
          ...state.addSaleData,
          vehicle_no: action.payload.vehiclenumber,
          discountamount:
            action.payload.discountamount == null
              ? 0
              : action.payload.discountamount,
        },
      };
    },
    getTotalReestimationAmountByPurchase: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    TotalReestimationAmountByPurchaseSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        addSaleData: {
          ...state.addSaleData,
          re_estimation_amount: action.payload.amount,
        },
      };
    },
    getTotalRepairAmountByPurchase: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    TotalRepairAmountByPurchaseSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        addSaleData: {
          ...state.addSaleData,
          repair_amount:
            action.payload.pendingcount !== 0 ? null : action.payload.amount,
          // action.payload.amount,
        },
      };
    },

    //***************** */

    addSaleInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter_id":
          return {
            ...state,
            addSaleData: {
              date: moment(),
              counter_id: state.addSaleData.counter_id,
              [action.payload.key]: action.payload.value,
              financesale:
                action.payload.value != 1
                  ? state.addSaleData.financesale
                  : null,
              ovfdetails:
                state.addSaleData.saletype == 3
                  ? state.addSaleData.ovfdetails
                  : null,
            },
            vehicleVersion: {},
            minSaleAmount: 0,
            maxSaleAmount: 0,
          };
          break;
          case "vehicle_id":
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData, // Spread existing addSaleData properties
                [action.payload.key]: action.payload.value, 
                ovfdetails: {
                  ...state.addSaleData.ovfdetails, // Preserve existing ovfdetails properties, if any
                  totalRepairAmountType: state.vehicleVersion?.brandfamily?.is_ve_required ? 2 : 1,
                },
              },
            };

        case "totalEstimationRepair":
          if(state.addSaleData?.ovfdetails?.vehicleEstimation_id?.total_estimation){
            return{
              ...state,
              addSaleData:{
                ...state.addSaleData, // Preserve existing addSaleData properties
                totalEstimationRepair:{
                  ...state.addSaleData.totalEstimationRepair, // Preserve existing,
                  [action.payload.key]: action.payload.value, 
                }
              }
            }
          }
          return{
            ...state,
            addSaleData:{
              ...state.addSaleData, // Preserve existing addSaleData properties
              totalEstimationRepair:{
                ...state.addSaleData.totalEstimationRepair, // Preserve existing,
                [action.payload.key]: action.payload.value, 
              }
            }
          }
          break;
        case "saletype":
          return {
            ...state,
            addSaleData: {
              date: moment(),
              counter_id: state.addSaleData.counter_id,
              [action.payload.key]: action.payload.value,
              financesale:
                action.payload.value != 1
                  ? state.addSaleData.financesale
                  : null,
              ovfdetails:
                state.addSaleData.saletype == 3
                  ? state.addSaleData.ovfdetails
                  : null,
            },
            vehicleVersion: {},
            minSaleAmount: 0,
            maxSaleAmount: 0,
          };
          break;

        case "repair_amount":
          return {
            ...state,
            addSaleData: {
              ...state.addSaleData,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "mc_internal_type":
          if (action.payload.value == 1) {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                [action.payload.key]: action.payload.value,
                mcamount: null,
                externalmcname: null,
                externalmcmobile: null,
                externalmcbank_id: null,
                externalmcbankacno: null,
                externalmcifsccode: null,
              },
            };
          } else if (action.payload.value == 2) {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                [action.payload.key]: action.payload.value,
                mcinternal_id: null,
                mcamount: null,
              },
            };
          } else {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                [action.payload.key]: action.payload.value,
                mcinternal_id: null,
                mcamount: null,
                externalmcname: null,
                externalmcmobile: null,
                externalmcbank_id: null,
                externalmcbankacno: null,
                externalmcifsccode: null,
              },
            };
          }
          break;
        default:
          return {
            ...state,
            addSaleData: {
              ...state.addSaleData,
              [action.payload.key]: action.payload.value,
            },
          };
      }
    },
    financeSaleInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "tenures":
          return {
            ...state,
            addSaleData: {
              ...state.addSaleData,
              financesale: {
                ...state.addSaleData.financesale,
                [action.payload.key]: action.payload.value,
                interestpercentage_id: null,
                interesttype: null,
                emi_firstdate: null,
                emi_amount: null,
                interestamount: null,
                totalrepaymentamount: null,
              },
            },
            emiDifferenceDays: 0,
          };
          break;

        case "interesttype":
          return {
            ...state,
            addSaleData: {
              ...state.addSaleData,
              financesale: {
                ...state.addSaleData?.financesale,
                [action.payload.key]: action.payload.value,
                interestpercentage_id: null,
                emi_firstdate: null,
                interestfixed: null,
                emi_amount: null,
                interestamount: null,
                totalrepaymentamount: null,
              },
            },
          };
          break;
        case "interestpercentage_id":
          return {
            ...state,
            addSaleData: {
              ...state.addSaleData,
              financesale: {
                ...state.addSaleData?.financesale,
                [action.payload.key]: action.payload.value,
                emi_firstdate: null,
                emi_amount: null,
                interestamount: null,
                totalrepaymentamount: null,
              },
            },
          };
          break;
        case "emi_firstdate":
          return {
            ...state,
            addSaleData: {
              ...state.addSaleData,
              financesale: {
                ...state.addSaleData?.financesale,
                [action.payload.key]: action.payload.value,
                emi_amount: null,
                interestamount: null,
                totalrepaymentamount: null,
                emi_date: parseInt(
                  moment(action.payload.value, "DD-MM-YYYY").format("DD")
                ),
              },
            },
          };
          break;

        default:
          return {
            ...state,
            addSaleData: {
              ...state.addSaleData,
              financesale: {
                ...state.addSaleData.financesale,
                [action.payload.key]: action.payload.value,
              },
            },
          };
          break;
      }
    },
    ovfSaleInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "is_new_vehicle":
          if (action.payload.value) {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                ovfdetails: {
                  ...state.addSaleData.ovfdetails,
                  [action.payload.key]: action.payload.value,
                  hypothecation: false,
                  loanstatus: false,
                  is_internal_financier: false,
                  internal_financier_id: null,
                  external_financier_name: null,
                  external_financier_bank_id: null,
                  external_financier_bank_accno: null,
                  external_financier_ifsccode: null,
                  loanamount: null,
                  loannumber: null,
                },
              },
            };
          } else {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                ovfdetails: {
                  ...state.addSaleData.ovfdetails,
                  [action.payload.key]: action.payload.value,
                  showroom_id: null,
                },
              },
            };
          }
          break;

          case "vehicleEstimation_id":
            // Check if total_estimation exists in vehicleEstimation_id
            if (!state.addSaleData?.ovfdetails?.vehicleEstimation_id?.total_estimation) {
              return {
                ...state,
                addSaleData: {
                  ...state.addSaleData, // Preserve existing properties
                  totalEstimationRepair: {
                    ...state.addSaleData.totalEstimationRepair, // Preserve existing properties
                    totalEstimationRepair: action.payload.value?.total_estimation, // Set totalEstimationRepair
                  },
                  ovfdetails: {
                    ...state.addSaleData.ovfdetails, // Preserve ovfdetails
                    [action.payload.key]: action.payload.value, // Update vehicleEstimation_id
                  },
                },
              };
            } else {
              // When total_estimation doesn't exist
              return {
                ...state,
                addSaleData: {
                  ...state.addSaleData, // Preserve existing properties
                  ovfdetails: {
                    ...state.addSaleData.ovfdetails, // Preserve ovfdetails
                    [action.payload.key]: action.payload.value, // Update vehicleEstimation_id
                  },
                },
              };
            }
          

        case "hypothecation":
          if (action.payload.value) {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                ovfdetails: {
                  ...state.addSaleData.ovfdetails,
                  [action.payload.key]: action.payload.value,
                },
              },
            };
          } else {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                ovfdetails: {
                  ...state.addSaleData.ovfdetails,
                  [action.payload.key]: action.payload.value,
                  loanstatus: false,
                  is_internal_financier: false,
                  internal_financier_id: null,
                  external_financier_name: null,
                  external_financier_bank_id: null,
                  external_financier_bank_accno: null,
                  external_financier_ifsccode: null,
                  loanamount: null,
                  loannumber: null,
                },
              },
            };
          }
          break;

          case "vehicle_id":
            return {

              ...state,
              addSaleData: {
                ...state.addSaleData.ovfdetails,
                totalRepairAmountType:state.vehicleVersion?.brandfamily?.is_ve_required ? 2 : 1,
              }
            }

        case "loanstatus":
          if (action.payload.value) {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                ovfdetails: {
                  ...state.addSaleData.ovfdetails,
                  [action.payload.key]: action.payload.value,
                  is_internal_financier: false,
                  internal_financier_id: null,
                  external_financier_name: null,
                },
              },
            };
          } else {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                ovfdetails: {
                  ...state.addSaleData.ovfdetails,
                  [action.payload.key]: action.payload.value,
                  is_internal_financier: false,
                  internal_financier_id: null,
                  external_financier_name: null,
                  external_financier_bank_id: null,
                  external_financier_bank_accno: null,
                  external_financier_ifsccode: null,
                  loanamount: null,
                },
              },
            };
          }
          break;
        case "is_internal_financier":
          if (action.payload.value) {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                ovfdetails: {
                  ...state.addSaleData.ovfdetails,
                  [action.payload.key]: action.payload.value,
                  external_financier_name: null,
                  external_financier_bank_id: null,
                  external_financier_bank_accno: null,
                  external_financier_ifsccode: null,
                },
              },
            };
          } else {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                ovfdetails: {
                  ...state.addSaleData.ovfdetails,
                  [action.payload.key]: action.payload.value,
                  internal_financier_id: null,
                },
              },
            };
          }
          break;
        case "loanamount":
          return {
            ...state,
            addSaleData: {
              ...state.addSaleData,
              ovfdetails: {
                ...state.addSaleData.ovfdetails,
                [action.payload.key]: action.payload.value,
                customeramount: null,
              },
              financesale: {
                ...state.addSaleData.financesale,
                financeamount: null,
              },
            },
          };
          break;
        case "insurancestatus":
          if (action.payload.value) {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                ovfdetails: {
                  ...state.addSaleData.ovfdetails,
                  [action.payload.key]: action.payload.value,
                },
              },
            };
          } else {
            return {
              ...state,
              addSaleData: {
                ...state.addSaleData,
                ovfdetails: {
                  ...state.addSaleData.ovfdetails,
                  [action.payload.key]: action.payload.value,
                  insurancecompany_id: null,
                  insurancenumber: null,
                  insuranceexpdate: null,
                },
              },
            };
          }
          break;
        case "estimationamount":
          return {
            ...state,
            addSaleData: {
              ...state.addSaleData,
              ovfdetails: {
                ...state.addSaleData.ovfdetails,
                [action.payload.key]: action.payload.value,
                customeramount: null,
              },
              financesale: {
                ...state.addSaleData.financesale,
                financeamount: null,
              },
            },
          };
          break;
        default:
          return {
            ...state,
            addSaleData: {
              ...state.addSaleData,
              ovfdetails: {
                ...state.addSaleData.ovfdetails,
                [action.payload.key]: action.payload.value,
              },
            },
          };
          break;
      }
    },
    getMCApplicable: (state) => {
      if (state.mcApplicableParams?.page == 1) {
        return {
          ...state,
          loading: true,
          mcApplicableList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    MCApplicableSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.mcApplicableParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.mcApplicableList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.mcApplicableParams?.page_size
      );
      return {
        ...state,
        mcApplicableList: list,
        loading: false,
        mcApplicableParams: {
          ...state.mcApplicableParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMCApplicableParams: (state, action) => {
      return {
        ...state,
        mcApplicableParams: action.payload,
      };
    },

    // ****** Add Sale Image Upload

    getAddSaleImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        imageLooading: true,
        addSaleData: {
          ...state.addSaleData,
          [action.payload.imageType]: null,
        },
      };
    },
    addSaleImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        imageLooading: false,
        addSaleData: {
          ...state.addSaleData,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },
    getAddFinanceSaleImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        imageLooading: true,
        addSaleData: {
          ...state.addSaleData,
          financesale: {
            ...state.addSaleData.financesale,
            [action.payload.imageType]: null,
          },
        },
      };
    },
    addFinanceSaleImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        imageLooading: false,
        addSaleData: {
          ...state.addSaleData,
          financesale: {
            ...state.addSaleData.financesale,
            [action.payload.ImageType]: action.payload.response.data,
            [action.payload.ImageType + "_id"]: action.payload.response.data.id,
          },
        },
      };
    },

    GetCounterByID: (state, action) => {
      return {
        ...state,
        loading: true,
        counterData: {},
      };
    },

    getVehicleVersion: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleVersion: action.payload,
      };
    },

    getFormulaExecuter: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    FormulaExecuterSuccessful: (state, action, code) => {
      switch (action.payload.formulaCode) {
        case "INCENTIVECHARGES":
          return {
            ...state,
            loading: false,
            addSaleData: {
              ...state.addSaleData,
              incentive_amount: parseFloat(action.payload.response).toFixed(2),
            },
          };
          break;
        case "MINSALEAMOUNT":
          return {
            ...state,
            loading: false,
            minSaleAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;
        case "MAXSALEAMOUNT":
          return {
            ...state,
            loading: false,
            maxSaleAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;
        case "MAXSALEAMOUNT_MINISALEAMOUNT":
          return {
            ...state,
            loading: false,
            maxSaleAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;
        case "SALEPROFIT":
          return {
            ...state,
            loading: false,
            addSaleData: {
              ...state.addSaleData,
              profit: parseFloat(action.payload.response).toFixed(2),
            },
          };
          break;
        case "CUSTOMERPAYAMOUNT":
          if (state.addSaleData.saletype == 2) {
            return {
              ...state,
              loading: false,
              addSaleData: {
                ...state.addSaleData,
                total: parseFloat(action.payload.response).toFixed(2),
                customerbankamount: 0?.toString(),
              },
            };
          } else if (state.addSaleData.saletype == 1) {
            return {
              ...state,
              loading: false,
              addSaleData: {
                ...state.addSaleData,
                total: parseFloat(action.payload.response).toFixed(2),
                customerbankamount: 0?.toString(),
                customercashamount: parseInt(action.payload.response),
              },
            };
          } else if (state.addSaleData.saletype == 3) {
            return {
              ...state,
              loading: false,
              addSaleData: {
                ...state.addSaleData,
                total: parseFloat(action.payload.response).toFixed(2),
                // customerbankamount: 0?.toString(),
                // customercashamount: parseInt(
                //   state.addSaleData.saletype == 1
                //     ? action.payload.response
                //     : state.addSaleData?.ovfdetails?.customeramount == null
                //     ? 0
                //     : state.addSaleData?.ovfdetails?.customeramount
                // ),
              },
            };
          }

          break;
        case "OVFCUSTOMERPAYAMOUNT":
          return {
            ...state,
            loading: false,
            addSaleData: {
              ...state.addSaleData,
              customerbankamount: 0?.toString(),
              customercreditamount: 0?.toString(),
              customercashamount: parseInt(action.payload.response),
              ovfdetails: {
                ...state.addSaleData.ovfdetails,
                ovfCustomerPayAmount: parseInt(action.payload.response),
              },
            },
          };
          break;
        case "CASHAMOUNT":
          return {
            ...state,
            loading: false,
            addSaleData: {
              ...state.addSaleData,
              customercashamount: parseFloat(action.payload.response).toFixed(
                2
              ),
            },
          };
          break;
        case "MINDOWNPAYMENTAMOUNT":
          return {
            ...state,
            loading: false,
            minDownPaymentAmount: parseFloat(action.payload.response).toFixed(
              2
            ),
          };
          break;
        case "FINANCEAMOUNT":
          return {
            ...state,
            loading: false,
            addSaleData: {
              ...state.addSaleData,
              financesale: {
                ...state.addSaleData.financesale,
                financeamount: parseFloat(action.payload.response).toFixed(2),
              },
            },
          };
          break;
        case "MAXFINANCEAMOUNT":
          return {
            ...state,
            loading: false,
            maxFinanceAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;

        case "DOCUMENTCHARGES":
          return {
            ...state,
            loading: false,
            addSaleData: {
              ...state.addSaleData,
              financesale: {
                ...state.addSaleData?.financesale,
                documentationcharges: parseFloat(
                  action.payload.response
                ).toFixed(2),
              },
            },
          };
          break;
        case "EMIAMOUNT":
          var emi_amount = action.payload.response;
          var interstPerDay =
            (emi_amount * parseInt(state.addSaleData.financesale?.tenures) -
              state.addSaleData.total) /
            parseInt(state.addSaleData.financesale?.tenures) /
            30;
          var extraInterst = interstPerDay * state.emiDifferenceDays;
          var totalEmiAmount =
            emi_amount +
            extraInterst / parseInt(state.addSaleData.financesale?.tenures);
          return {
            ...state,
            loading: false,
            addSaleData: {
              ...state.addSaleData,
              financesale: {
                ...state.addSaleData?.financesale,
                emi_amount: parseFloat(totalEmiAmount).toFixed(2),
                totalrepaymentamount: parseFloat(
                  totalEmiAmount *
                    parseInt(state.addSaleData.financesale?.tenures)
                ).toFixed(2),
                interestamount: parseFloat(
                  parseFloat(
                    totalEmiAmount *
                      parseInt(state.addSaleData.financesale?.tenures)
                  ) - state.addSaleData.total
                ).toFixed(),
              },
            },
          };
          break;
        case "GSTAMOUNT":
          return {
            ...state,
            loading: false,
            addSaleData: {
              ...state.addSaleData,
              gstamount: parseFloat(action.payload.response).toFixed(2),
            },
          };
          break;
        case "MAXOVFDPDUEAMOUNT":
          return {
            ...state,
            loading: false,
            maxOVFDPDUEAmount: parseFloat(action.payload.response).toFixed(2),
            addSaleData: {
              ...state.addSaleData,
            },
          };
          break;
        case "OVFFINANCEAMOUNT":
          return {
            ...state,
            loading: false,
            addSaleData: {
              ...state.addSaleData,
              financesale: {
                ...state.addSaleData.financesale,
                financeamount: parseFloat(action.payload.response).toFixed(2),
              },
            },
          };
          break;
      }
    },

    vechicleSaleDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    setParams: (state, action) => {
      return {
        ...state,
        params: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            vehicleSaleParams: {
              ...state.vehicleSaleParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            vehicleSaleParams: {
              ...state.vehicleSaleParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            vehicleSaleParams: {
              ...state.vehicleSaleParams,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        default:
          return {
            ...state,
            vehicleSaleParams: {
              ...state.vehicleSaleParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   vehicleSaleParams: {
      //     ...state.vehicleSaleParams,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },
    

    // Field Verification Request

    getFieldRequestVerification: (state) => {
      return {
        ...state,
        loading: true,
        requestVerification: false,
      };
    },
    fieldRequestVerificationSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        requestVerification: action.payload,
      };
    },

    isRequestModelVisible: (state, action) => {
      return {
        ...state,
        RequestMode: action.payload,
      };
    },
    requestModelInputChangeValue: (state, action) => {
      return {
        ...state,
        salesRequestData: {
          ...state.salesRequestData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    FieldVerificationRequest: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getCustomerById: (state, action) => {
      return {
        ...state,
        loading: true,
        customerByIdList: action.payload,
      };
    },

    // OVF Quotation Pending List
    getOVFQuotationPendingList: (state, action) => {
      if (state.OVFPendingParams?.page == 1) {
        return {
          ...state,
          loading: true,
          OVFQuotationPendingList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    OVFQuotationPendingListSuccessful: (state, action) => {
      var response = action.payload.response;
      var list = [];
      if (state.OVFQuotationPendingParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.OVFQuotationPendingList, ...response.results];
      }
      var noofpages = Math.ceil(
        response.count / state.OVFQuotationPendingParams?.page_size
      );

      return {
        ...state,
        listCount: response.count,
        OVFQuotationPendingList: list,
        // OVFSalequotList: action.payload?.results,
        loading: false,
        OVFQuotationPendingParams: {
          ...state.OVFQuotationPendingParams,
          no_of_pages: noofpages,
        },
      };
    },
    setOVFQuotationPendingParams: (state, action) => {
      return {
        ...state,
        OVFQuotationPendingParams: action.payload,
      };
    },
    insuranceNumberValidate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getTenureData: (state, action) => {
      return {
        ...state,
        loading: true,
        tenuresObjData: action.payload,
      };
    },
    getPurchaseById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    PurchaseByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        addSaleData: {
          ...state.addSaleData,
          vehicleback_image: action.payload.vehicleback_image,
          vehicleback_image_id: action.payload.vehicleback_image?.id,
          vehiclefront_image: action.payload.vehiclefront_image,
          vehiclefront_image_id: action.payload.vehiclefront_image?.id,
          vehicleleft_image: action.payload.vehicleleft_image,
          vehicleleft_image_id: action.payload.vehicleleft_image?.id,
          vehicleright_image: action.payload.vehicleright_image,
          vehicleright_image_id: action.payload.vehicleright_image?.id,
          insuranceimage: action.payload.insuranceimage,
          insuranceimage_id: action.payload.insuranceimage?.id,
          // c35_image: action.payload.c35_image,
          // c35_image_id: action.payload.c35_image?.id,
          rcfront_image: action.payload.rcfront_image,
          rcfront_image_id: action.payload.rcfront_image?.id,
          rcback_image: action.payload.rcback_image,
          rcback_image_id: action.payload.rcback_image?.id,
          pollution_image: action.payload.pollution_image,
          pollution_image_id: action.payload.pollution_image?.id,
          // coveringletter_image: action.payload.coveringletter_image,
          // coveringletter_image_id: action.payload.coveringletter_image?.id,
          // estimation_slip_image: action.payload.estimation_slip_image,
          // estimation_slip_image_id: action.payload.estimation_slip_image?.id,
        },
      };
    },
    getFVRequestData: (state, action) => {
      return {
        ...state,
        loading: true,
        fVRequestData: [],
      };
    },
    FVRequestDataSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        fVRequestData: action.payload.results,
      };
    },
    isFvViewModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          viewModel: action.payload.model,
          fVRequestModelData: action.payload.fvData,
        };
      } else {
        return {
          ...state,
          viewModel: action.payload.model,
          fVRequestModelData: {},
        };
      }
    },
    // setSaleFilterParams: (state, action) => {
    //   return {
    //     ...state,
    //     saleFilterParams: action.payload,
    //   };
    // },

    // Formula Calculation

    setEmiDifferenceDays: (state, action) => {
      return {
        ...state,
        emiDifferenceDays: action.payload,
      };
    },

    getMinSaleAmount: (state, action) => {
      return {
        ...state,
      };
    },
    getEMIAmount: (state, action) => {
      return {
        ...state,
      };
    },

    getMaxSaleAmount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getMaxSaleAmountInMinSale: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getGSTAmount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getSaleProfit: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getIncentiveAmount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getDocumentCharges: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getSaleCustomerPayAmount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getMinDownPaymentAmount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getFinanceAmount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getCustomerCashAmount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getMaxFinanceAmount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getOVFCustomerPayAmount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getTenuresData: (state) => {
      return {
        ...state,
        tenuresList: [],
        loading: true,
      };
    },
    tenuresDataSuccessful: (state, action) => {
      return {
        ...state,
        tenuresList: action.payload,
        loading: false,
      };
    },
    getMiniEMIdates: (state) => {
      return {
        ...state,
        miniEMIdatesList: [],
        loading: true,
      };
    },
    miniEMIdatesSuccessful: (state, action) => {
      console.log("miniEMIdatesSuccessful>>>>>>>>>>>>>>>>>>", action.payload);
      return {
        ...state,
        miniEMIdatesList: action.payload,
        loading: false,
      };
    },
    clearVehicleSaleData: (state, action) => {
      return {
        ...state,
        loading: true,
        addSaleData: {
          date: moment(),
          hypothecationisinternal: false,
          loanstatus: false,
        },
        vehicleSaleData: {},
        vehicleVersion: {},
      };
    },
    getRejectReasonData: (state, action) => {
      return {
        ...state,
        loading: true,
        rejectReasonObjData: action.payload,
      };
    },
    getMaxOVFDPDueAMount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getOVFFinanceAmount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    submitCould: (state, action) => {
      return {
        ...state,
        loading: true
      };
    },
    // getCreatLoanAccount:(state, action) => {
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getVehiclesList,
  vehicleListSuccessful,
  clearSalesData,
  addSale,
  getSaleDataQuotationById,
  SaleDataQuotationByIdSuccessful,
  getTotalReestimationAmountByPurchase,
  TotalReestimationAmountByPurchaseSuccessful,
  getTotalRepairAmountByPurchase,
  TotalRepairAmountByPurchaseSuccessful,
  getOVFSaleQuotationById,
  OVFSaleQuotationByIdSuccessful,
  getSalesCheckApprove,
  salesCheckApproveSuccessful,
  salesApproval,
  isRejectModelVisible,
  rejectModelInputChangeValue,
  vechicleSaleDelete,
  getMCApplicable,
  MCApplicableSuccessful,
  setMCApplicableParams,
  getVehicleVersion,
  addSaleInputChangeValue,
  financeSaleInputChangeValue,
  ovfSaleInputChangeValue,
  GetCounterByID,
  getFormulaExecuter,
  FormulaExecuterSuccessful,
  isDrawerVisible,
  getAddSaleImageUpload,
  addSaleImageUploadSuccessful,
  getAddFinanceSaleImageUpload,
  addFinanceSaleImageUploadSuccessful,
  setParams,
  apiError,
  getPurchaseHistory,
  purchaseHistoryListSuccessful,
  getSaleHistory,
  saleHistoryListSuccessful,
  getVehicleSaleDoc,
  vehicleSaleDocSuccessful,
  setVehicleSaleParams,
  FilterInputChangeValue,
  vehicleSaleActiveTab,
  getFieldRequestVerification,
  fieldRequestVerificationSuccessful,
  isRequestModelVisible,
  requestModelInputChangeValue,
  FieldVerificationRequest,
  getCustomerById,
  OVFQuotationPendingListSuccessful,
  getOVFQuotationPendingList,
  setOVFQuotationPendingParams,
  insuranceNumberValidate,
  getTenureData,
  getPurchaseById,
  PurchaseByIdSuccessful,
  getFVRequestData,
  FVRequestDataSuccessful,
  isFvViewModelVisible,
  // setSaleFilterParams,
  getMinSaleAmount,
  getEMIAmount,
  getMaxSaleAmount,
  getMaxSaleAmountInMinSale,
  getGSTAmount,
  getSaleProfit,
  getIncentiveAmount,
  getDocumentCharges,
  getSaleCustomerPayAmount,
  getMinDownPaymentAmount,
  getFinanceAmount,
  getCustomerCashAmount,
  getMaxFinanceAmount,
  getTenuresData,
  tenuresDataSuccessful,
  setEmiDifferenceDays,
  getMiniEMIdates,
  miniEMIdatesSuccessful,
  clearVehicleSaleData,
  getRejectReasonData,
  clearVehiclesList,
  getOVFCustomerPayAmount,
  getMaxOVFDPDueAMount,
  getOVFFinanceAmount,
  fsyncStatusUpdate,
  submitCould,
  // getCreatLoanAccount,
} = vehicleCounterSalesSlice.actions;

export default vehicleCounterSalesSlice.reducer;
