import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  itemsListSuccessful,
  vehicleEstimationListSuccessful,
  getVehicleEstimation,
  vehicleEstimationByIdSuccessful,
  getVehicleEstimationById,
  vehicleEstPendingListSuccessful,
  estiCumulativeItemSuccessful,
  addVehicleEstSuccessful,
  getVehicleEstPending
} from "./vehicleEstimationSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";


function* VehicleEstimationList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/vehicleestimationlist", params);
    yield put(vehicleEstimationListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* VehicleEstimationAdd({ payload }) {
  try {
    const response = yield call(postAdd, "/purchase/vehicleestimation", payload.obj);
    if (response.status === 200 || response.status === 201) {
      const formData = serialize(payload.profileObj);
      const response2 = yield call(
        postEdit,
        "/purchase/vehicleestimationimages/" + response.data?.id,
        formData
      );
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Vehicle Estimation Added Successfully.!
            </p>
          </div>
        ),
      });
      yield put(getVehicleEstimation({ page: 1, search: "", page_size: 10 }));
      yield put(addVehicleEstSuccessful())
      payload.history.push("/pages/vehicle_estimation");
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to add Vehicle Estimation. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}



function* ItemsList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/item/" + payload.vehicle_id, params);
    yield put(itemsListSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}








function* VehicleEstimationById({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/purchase/vehicleestimation/" + payload,
      {}
    );
    yield put(vehicleEstimationByIdSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* VehicleApprove({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/purchase/vehicleestimationapproval/" + payload.id,
      payload
    );
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Vehicle Estimation has been Approved Successfully!
            </p>
          </div>
        ),
      });
      yield put(getVehicleEstPending({ page: 1, search: "", page_size: 10, id:payload.id }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your  Vehicle Estimation has been not Approved !
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}


function* VehicleEstPendingList({ payload }) {
  var data = payload;
  var params = {};
  
  try {
    const response = yield call(getParamsList, "/masters/estimation/vehicles", params);
    yield put(vehicleEstPendingListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* EstCumuluativeItems({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/purchase/Allvehicleestimationbyvehicle/" + payload.id,
      {}
    );
    yield put(estiCumulativeItemSuccessful({ response, type: payload.type }));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetVehicleEstimation() {
  yield takeEvery("vehicleEstimation/getVehicleEstimation", VehicleEstimationList);
}
export function* watchGetVehicleEstimationById() {
  yield takeEvery("vehicleEstimation/getVehicleEstimationById", VehicleEstimationById);
}
export function* watchAddVehilceEstimation() {
  yield takeEvery("vehicleEstimation/addVehilceEstimation", VehicleEstimationAdd);
}
export function* watchGetItemsList() {
  yield takeEvery("vehicleEstimation/getItemsList", ItemsList);
}
export function* watchApproveVehicle() {
  yield takeEvery("vehicleEstimation/approveVehicle", VehicleApprove);
}
export function* watchGetVehicleEstPending() {
  yield takeEvery("vehicleEstimation/getVehicleEstPending", VehicleEstPendingList);
}
export function* watchGetEstCumuluativeItems() {
  yield takeEvery("vehicleEstimation/getEstCumuluativeItems", EstCumuluativeItems);
}
function* vehicleEstimationSaga() {
  yield all([
    fork(watchGetVehicleEstimation),
    fork(watchGetVehicleEstimationById),
    fork(watchAddVehilceEstimation),
    fork(watchGetItemsList),
    fork(watchApproveVehicle),
    fork(watchGetVehicleEstPending),
    fork(watchGetEstCumuluativeItems),
  ]);
}

export default vehicleEstimationSaga;
