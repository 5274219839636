import { createSlice } from "@reduxjs/toolkit";

export const brandfamilySlice = createSlice({
  name: "brandfamily",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    brandfamilylist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    brandfamilyParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getBrandfamilys: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    brandfamilyListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.brandfamilyParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        brandfamilylist: action.payload.response.results,
        loading: false,
        brandfamilyParams: {
          ...state.brandfamilyParams,
          no_of_pages: noofpages,
        },
      };
    },
    brandfamilyAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    brandfamilyEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          brand_id: action.payload.brand.id,
          is_ve_required:action.payload.is_ve_required
        },
      };
    },
    brandfamilyDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetBrandfamilysParams: (state, action) => {
      return {
        ...state,
        brandfamilyParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getBrandfamilys,
  brandfamilyListSuccessful,
  brandfamilyAdd,
  brandfamilyEdit,
  brandfamilyDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetBrandfamilysParams
} = brandfamilySlice.actions;

export default brandfamilySlice.reducer;
