import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { Modal } from "antd";

export const miniSlice = createSlice({
  name: "mini",
  initialState: {
    userAccessList: [],
    miniCountryList: [],
    miniStatesList: [],
    miniCityList: [],
    miniAreaList: [],
    miniDistrictList: [],
    miniDepartmentList: [],
    miniFirmList: [],
    miniVehiclesList: [],
    miniGodownList:[],
    miniVehiclesListLoading: false,
    miniExecutiveList:[],
    miniExecutiveListLoading: false,
    miniGodownListLoading:false,
    miniVehiclesNonStockList: [],
    miniVehEstByVehicleList:[],
    miniSeizedVehicleList:[],
    miniGateInVehicleList:[],
    miniVehicleStockLoading: false,
    miniVehEstByVehicle:false,
    purchaseAvaliableStockList: [],
    purchaseStockList: [],
    saleQuotationStockList: [],
    miniCountersList: [],
    miniCounterLoading: false,
    allCountersList: [],
    miniZonesList: [],
    miniClustersList: [],
    miniDivisionsList: [],
    miniBanksList: [],
    miniOccupationList: [],
    miniBrandsList: [],
    miniBrandLoading: false,
    miniBrandFamilyList: [],
    miniBrandFamilyLoading: false,
    miniColorsList: [],
    versionColorsList: [],
    miniFinanciersList: [],
    miniMechanicsList: [],
    miniAddressProofList: [],
    miniEmissionnorms: [],
    miniPriority: [],
    miniVersionList: [],
    miniModelsList: [],
    miniModelLoading: false,
    miniPercentageInterestsList: [],
    miniRejectReasonsList: [],
    miniPurchaseEnquiryList: [],
    miniSaleQuotationList: [],
    miniVehicleEstimationList: [],
    // miniEMIdatesList: [],
    miniInsuranceList: [],
    miniVehicletypesList: [],
    miniTenuresList: [],
    miniManagersList: [],
    searchVehicleNonStockId: 0,
    FVRequestUsersList: [],
    miniRepairTypesList: [],
    miniLeaveReasonList: [],
    miniUsersList: [],
    miniCustomerList: [],
    miniReportingToUsersList: [],
    miniAssetList: [],
    financeOVFList: [],

    miniCustomerParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    FVRequestUsersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    purchaseEnquiryParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    miniVehiclesNonStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniVehicleEstimationByVehicleParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniVehiclesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    miniSeizedVehiclesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    miniGateInVehiclesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    miniCollectionExecutiveParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    miniGodownParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },

    purchaseAvailableStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    purchaseStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    saleQuotationStockListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniUsersListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    mechanicParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniReportingToUsersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniAssetParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    financeOVFParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    insurancePendingList: [],
    insurancePendingParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },

    purchaseRepairStockList: [],
    purchaseRepairStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },

    purchaseRefurbishedkList: [],
    purchaseRefurbishedParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },

    miniServiceMechanicList: [],
    serviceMechanicParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniMechLoading: false,

    miniRampList: [],
    miniRampParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniRampLoading: false,
  },
  reducers: {
    getUserPermissions: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    userPermissionsSuccessful: (state) => {
      return {
        ...state,
        userAccessList: action.payload.response.data,
        loading: false,
      };
    },
    getMinicountry: (state, action) => {
      return {
        ...state,
        miniCountryList: [],
        miniStatesList: [],
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
        loading: true,
      };
    },
    minicountrySuccessful: (state, action) => {
      return {
        ...state,
        miniCountryList: action.payload,
        loading: false,
      };
    },
    getMinicountryClearData: (state, action) => {
      return {
        ...state,
        miniCountryList: [],
        miniStatesList: [],
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
        loading: true,
      };
    },
    getMiniStates: (state, action) => {
      return {
        ...state,
        miniStatesList: [],
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
        loading: true,
      };
    },
    getMiniSeizedVehicles: (state, action) => {
      return {
        ...state,
        miniSeizedVehicleList:[],
        loading: true,
      };
    },
    getMiniGateInVehicles: (state, action) => {
      return {
        ...state,
        miniGateInVehicleList:[],
        loading: true,
      };
    },
    miniSeizedVehiclesSuccessful: (state, action) => {
      return {
        ...state,
        miniSeizedVehicleList: action.payload.results,
        loading: false,
      };
    },
    miniGateInVehiclesSuccessful: (state, action) => {
      return {
        ...state,
        miniGateInVehicleList: action.payload.results,
        loading: false,
      };
    },
    miniStatesSuccessful: (state, action) => {
      return {
        ...state,
        miniStatesList: action.payload,
        loading: false,
      };
    },
    getMiniStatesClearData: (state, action) => {
      return {
        ...state,
        miniStatesList: [],
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
      };
    },
    getMiniDistrict: (state) => {
      return {
        ...state,
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
        loading: true,
      };
    },
    MiniDistrictSuccessful: (state, action) => {
      return {
        ...state,
        miniDistrictList: action.payload,
        loading: false,
      };
    },
    getMiniDistrictClearData: (state) => {
      return {
        ...state,
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
      };
    },
    getMiniCity: (state) => {
      return {
        ...state,
        miniCityList: [],
        miniAreaList: [],
        loading: true,
      };
    },
    miniCitySuccessful: (state, action) => {
      return {
        ...state,
        miniCityList: action.payload,
        loading: false,
      };
    },
    getMiniCityClearData: (state) => {
      return {
        ...state,
        miniCityList: [],
        miniAreaList: [],
      };
    },
    getMiniArea: (state) => {
      return {
        ...state,
        miniAreaList: [],
        loading: true,
      };
    },
    miniAreaSuccessful: (state, action) => {
      return {
        ...state,
        miniAreaList: action.payload,
        loading: false,
      };
    },
    getMiniAreaClearData: (state) => {
      return {
        ...state,
        miniAreaList: [],
      };
    },
    getMiniDepartment: (state) => {
      return {
        ...state,
        miniDepartmentList: [],
        loading: true,
      };
    },
    miniDepartmentSuccessful: (state, action) => {
      return {
        ...state,
        miniDepartmentList: action.payload,
        loading: false,
      };
    },
    getMiniFirm: (state) => {
      return {
        ...state,
        miniFirmList: [],
        loading: true,
      };
    },
    miniFirmSuccessful: (state, action) => {
      return {
        ...state,
        miniFirmList: action.payload,
        loading: false,
      };
    },
    getMiniVehicles: (state) => {
      if (state.miniVehiclesParams?.page == 1) {
        return {
          ...state,
          miniVehiclesListLoading: true,
          miniVehiclesList: [],
        };
      } else {
        return {
          ...state,
          miniVehiclesListLoading: true,
        };
      }
    },
    getMiniTwoVehicles: (state) => {
      if (state.miniVehiclesParams?.page == 1) {
        return {
          ...state,
          miniVehiclesListLoading: true,
          miniVehiclesList: [],
        };
      } else {
        return {
          ...state,
          miniVehiclesListLoading: true,
        };
      }
    },

    getMiniCollectionExecutive : (state,action) => {
      if (state.miniCollectionExecutiveParams?.page == 1) {
        return {
          ...state,
          miniExecutiveListLoading: true,
          miniExecutiveList: [],
        };
      } else {
        return {
          ...state,
          miniExecutiveListLoading: true,
        };
      }

    },

    miniCollectionExecutiveSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniCollectionExecutiveParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniExecutiveList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniCollectionExecutiveParams?.page_size
      );
      return {
        ...state,
        miniExecutiveList: list,
        miniExecutiveListLoading: false,
        miniCollectionExecutiveParams: {
          ...state.miniCollectionExecutiveParams,
          no_of_pages: noofpages,
        },
      };
    },

    getMiniGodown: (state,action) => {
        return {
          ...state,
          miniGodownListLoading: true,
          miniGodownList: [],
        };
    },
    
    miniVehiclesSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniVehiclesParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniVehiclesList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniVehiclesParams?.page_size
      );
      return {
        ...state,
        miniVehiclesList: list,
        miniVehiclesListLoading: false,
        miniVehiclesParams: {
          ...state.miniVehiclesParams,
          no_of_pages: noofpages,
        },
      };
    },

    miniGodownSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniGodownParams?.page == 1) {
        list = response;

      } else {
        list = [...state.miniGodownList, ...response];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniGodownParams?.page_size
      );
      return {
        ...state,
        miniGodownList: list,
        miniGodownListLoading: false,
        miniGodownParams: {
          ...state.miniGodownParams,
          no_of_pages: noofpages,
        },
      };
    },

    setMiniVehiclesParams: (state, action) => {
      return {
        ...state,
        miniVehiclesParams: action.payload,
      };
    },
    setMiniSeizedVehiclesParams: (state, action) => {
      return {
        ...state,
        miniSeizedVehiclesParams: action.payload,
      };
    },
    setMiniGateInVehiclesParams: (state, action) => {
      return {
        ...state,
        miniGateInVehiclesParams: action.payload,
      };
    },

    setMiniCollectionExecutiveParams: (state, action) => {
      return {
        ...state,
        miniCollectionExecutiveParams: action.payload,
      };
    },

    setMiniGodownParams: (state, action) => {
      return {
        ...state,
        miniGodownParams: action.payload,
      };
    },

    getMiniVehiclesNonStock: (state, action) => {
      if (state.miniVehiclesNonStockParams?.page == 1) {
        return {
          ...state,
          miniVehicleStockLoading: true,
          miniVehiclesNonStockList: [],
        };
      } else {
        return {
          ...state,
          miniVehicleStockLoading: true,
        };
      }
    },
    getMiniVehicleEstimationByVehicle: (state, action) => {
      if (state.miniVehicleEstimationByVehicleParams?.page == 1) {
        return {
          ...state,
          miniVehEstByVehicle: true,
          miniVehEstByVehicleList: [],
        };
      } else {
        return {
          ...state,
          miniVehEstByVehicle: true,
        };
      }
    },
    miniVehiclesNonStockSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniVehiclesNonStockParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniVehiclesNonStockList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniVehiclesNonStockParams?.page_size
      );
      return {
        ...state,
        miniVehiclesNonStockList: list,
        miniVehicleStockLoading: false,
        miniVehiclesNonStockParams: {
          ...state.miniVehiclesNonStockParams,
          no_of_pages: noofpages,
        },
      };
    },

    miniVehicleEstimationByVehicleSuccessful: (state, action) => {
      const response = action.payload;
      
      // Check if count is 0
      if (response.count === 0) {
        if (!state.errorShown) {
          Modal.error({
            title: "No Data Found",
            content: "No vehicle estimations found for the selected vehicle.",
            onOk: () => {
              // Optional: handle any action on OK
            },
          });
        }
        return {
          ...state,
          miniVehEstByVehicleList: [], // Clear the list if no data is found
          miniVehEstByVehicle: false,
          errorShown: true, // Set error flag
        };
      }
    
      // Transform results into the desired list format
      const list = response.results
    
      // Calculate the number of pages
      const noofpages = Math.ceil(
        response.count / state.miniVehicleEstimationByVehicleParams?.page_size
      );
    
      // Update the state with the transformed data
      return {
        ...state,
        miniVehEstByVehicleList: list, // Update the list with transformed data
        miniVehEstByVehicle: true,
        miniVehicleEstimationByVehicleParams: {
          ...state.miniVehicleEstimationByVehicleParams,
          no_of_pages: noofpages,
        },
        errorShown: false, // Reset error flag
      };
    },
    
    


    setMiniVehiclesNonStockParams: (state, action) => {
      return {
        ...state,
        miniVehiclesNonStockParams: action.payload,
      };
    },
    // Mini customers
    getMiniCustomers: (state, action) => {
      if (state.miniCustomerParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniCustomerList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniCustomerListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniCustomerParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniCustomerList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniCustomerParams?.page_size
      );
      return {
        ...state,
        miniCustomerList: list,
        loading: false,
        miniCustomerParams: {
          ...state.miniCustomerParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniCustomerParams: (state, action) => {
      return {
        ...state,
        miniCustomerParams: action.payload,
      };
    },
    getPurchaseAvailableStock: (state, action) => {
      if (state.purchaseAvailableStockParams?.page == 1) {
        return {
          ...state,
          loading: true,
          purchaseAvaliableStockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    purchaseAvailableStockSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.purchaseAvailableStockParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.purchaseAvaliableStockList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.purchaseAvailableStockParams?.page_size
      );
      return {
        ...state,
        purchaseAvaliableStockList: list,
        loading: false,
        purchaseAvailableStockParams: {
          ...state.purchaseAvailableStockParams,
          no_of_pages: noofpages,
        },
      };
    },
    clearPurchaseAvaliableStock: (state) => {
      return {
        ...state,
        purchaseAvaliableStockList: [],
      };
    },
    setPurchaseAvailableStockParams: (state, action) => {
      return {
        ...state,
        purchaseAvailableStockParams: action.payload,
      };
    },

    getPurchaseStock: (state, action) => {
      if (state.purchaseStockParams?.page == 1) {
        return {
          ...state,
          loading: true,
          purchaseStockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    purchaseStockSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.purchaseStockParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.purchaseStockList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.purchaseStockParams?.page_size
      );
      return {
        ...state,
        purchaseStockList: list,
        loading: false,
        purchaseStockParams: {
          ...state.purchaseStockParams,
          no_of_pages: noofpages,
        },
      };
    },
    setPurchaseStockParams: (state, action) => {
      return {
        ...state,
        purchaseStockParams: action.payload,
      };
    },
    getMiniCounters: (state) => {
      return {
        ...state,
        miniCountersList: [],
        miniCounterLoading: true,
      };
    },
    miniCountersSuccessful: (state, action) => {
      return {
        ...state,
        miniCountersList: action.payload,
        miniCounterLoading: false,
      };
    },
    getMiniCountersClearData: (state) => {
      return {
        ...state,
        miniCountersList: [],
      };
    },
    getAllCounters: (state) => {
      return {
        ...state,
        allCountersList: [],
        loading: true,
      };
    },
    allCountersSuccessful: (state, action) => {
      return {
        ...state,
        allCountersList: action.payload,
        loading: false,
      };
    },
    getMiniZones: (state) => {
      return {
        ...state,
        miniZonesList: [],
        loading: true,
      };
    },
    miniZonesSuccessful: (state, action) => {
      return {
        ...state,
        miniZonesList: action.payload,
        loading: false,
      };
    },
    getMiniZonesClearData: (state) => {
      return {
        ...state,
        miniZonesList: [],
      };
    },
    getMiniClusters: (state) => {
      return {
        ...state,
        miniClustersList: [],
        loading: true,
      };
    },
    miniClustersSuccessful: (state, action) => {
      return {
        ...state,
        miniClustersList: action.payload,
        loading: false,
      };
    },
    getMiniClustersClearData: (state) => {
      return {
        ...state,
        miniClustersList: [],
      };
    },
    getMiniDivisions: (state) => {
      return {
        ...state,
        miniDivisionsList: [],
        loading: true,
      };
    },
    miniDivisionsSuccessful: (state, action) => {
      return {
        ...state,
        miniDivisionsList: action.payload,
        loading: false,
      };
    },
    getMiniDivisionsClearData: (state) => {
      return {
        ...state,
        miniDivisionsList: [],
      };
    },
    getMiniBanks: (state) => {
      return {
        ...state,
        miniBanksList: [],
        loading: true,
      };
    },
    miniBanksSuccessful: (state, action) => {
      return {
        ...state,
        miniBanksList: action.payload,
        loading: false,
      };
    },
    getMiniOccupation: (state) => {
      return {
        ...state,
        miniOccupationList: [],
        loading: true,
      };
    },
    miniOccupationSuccessful: (state, action) => {
      return {
        ...state,
        miniOccupationList: action.payload,
        loading: false,
      };
    },
    getMiniBrands: (state) => {
      return {
        ...state,
        miniBrandsList: [],
        miniBrandLoading: true,
      };
    },
    miniBrandsSuccessful: (state, action) => {
      return {
        ...state,
        miniBrandsList: action.payload,
        miniBrandLoading: false,
      };
    },
    getMiniBrandsClearData: (state) => {
      return {
        ...state,
        miniBrandsList: [],
        miniBrandLoading: true,
      };
    },
    getMiniBrandFamily: (state) => {
      return {
        ...state,
        miniBrandFamilyList: [],
        miniBrandFamilyLoading: true,
      };
    },
    miniBrandFamilySuccessful: (state, action) => {
      return {
        ...state,
        miniBrandFamilyList: action.payload,
        miniBrandFamilyLoading: false,
      };
    },
    getMiniBrandFamilyClearData: (state) => {
      return {
        ...state,
        miniBrandFamilyList: [],
        miniBrandFamilyLoading: true,
      };
    },
    getMiniColors: (state) => {
      return {
        ...state,
        miniColorsList: [],
        loading: true,
      };
    },
    miniColorsSuccessful: (state, action) => {
      return {
        ...state,
        miniColorsList: action.payload,
        loading: false,
      };
    },
    getVersionColors: (state) => {
      return {
        ...state,
        versionColorsList: [],
        loading: true,
      };
    },
    versionColorsSuccessful: (state, action) => {
      return {
        ...state,
        versionColorsList: action.payload,
        loading: false,
      };
    },
    getMiniFinanciers: (state) => {
      return {
        ...state,
        miniFinanciersList: [],
        loading: true,
      };
    },
    miniFinanciersSuccessful: (state, action) => {
      return {
        ...state,
        miniFinanciersList: action.payload,
        loading: false,
      };
    },

    getMiniAddressProof: (state) => {
      return {
        ...state,
        miniAddressProofList: [],
        loading: true,
      };
    },
    miniAddressProofSuccessful: (state, action) => {
      return {
        ...state,
        miniAddressProofList: action.payload,
        loading: false,
      };
    },
    getMiniEmissionnorms: (state) => {
      return {
        ...state,
        miniEmissionnorms: [],
        loading: true,
      };
    },
    miniEmissionnormsSuccessful: (state, action) => {
      return {
        ...state,
        miniEmissionnorms: action.payload,
        loading: false,
      };
    },
    getMiniPriority: (state) => {
      return {
        ...state,
        miniPriority: [],
        loading: true,
      };
    },
    miniPrioritySuccessful: (state, action) => {
      return {
        ...state,
        miniPriority: action.payload,
        loading: false,
      };
    },
    getMiniPriorityClearData: (state) => {
      return {
        ...state,
        miniPriority: [],
        loading: true,
      };
    },
    getMiniVersion: (state) => {
      return {
        ...state,
        miniVersionList: [],
        loading: true,
      };
    },
    miniVersionSuccessful: (state, action) => {
      return {
        ...state,
        miniVersionList: action.payload,
        loading: false,
      };
    },
    getMiniVersionClearData: (state) => {
      return {
        ...state,
        miniVersionList: [],
        loading: true,
      };
    },
    getMiniModels: (state) => {
      return {
        ...state,
        miniModelsList: [],
        miniModelLoading: true,
      };
    },
    miniModelsSuccessful: (state, action) => {
      return {
        ...state,
        miniModelsList: action.payload,
        miniModelLoading: false,
      };
    },
    getMiniModelsClearData: (state) => {
      return {
        ...state,
        miniModelsList: [],
        miniModelLoading: true,
      };
    },
    getMiniPercentageInterests: (state) => {
      return {
        ...state,
        miniPercentageInterestsList: [],
        loading: true,
      };
    },
    miniPercentageInterestsSuccessful: (state, action) => {
      return {
        ...state,
        miniPercentageInterestsList: action.payload,
        loading: false,
      };
    },
    getMiniRejectReasons: (state) => {
      return {
        ...state,
        miniRejectReasonsList: [],
        loading: true,
      };
    },
    miniRejectReasonsSuccessful: (state, action) => {
      return {
        ...state,
        miniRejectReasonsList: action.payload,
        loading: false,
      };
    },
    getMiniPurchaseEnquiry: (state, action) => {
      if (state.purchaseEnquiryParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniPurchaseEnquiryList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniPurchaseEnquirySuccessful: (state, action) => {
      var response = action.payload.response;
      var list = [];
      if (state.purchaseEnquiryParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniPurchaseEnquiryList, ...response.results];
      }
      var noofpages = Math.ceil(
        response.count / state.purchaseEnquiryParams?.page_size
      );
      return {
        ...state,
        miniPurchaseEnquiryList: list,
        loading: false,
        purchaseEnquiryParams: {
          ...state.purchaseEnquiryParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniPurchaseEnquiryParams: (state, action) => {
      return {
        ...state,
        purchaseEnquiryParams: action.payload,
      };
    },
    getMiniMechanics: (state) => {
      if (state.mechanicParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniMechanicsList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniMechanicListSuccessful: (state, action) => {
      console.log("miniMechanicListSuccessful", action.payload);
      var response = action.payload;
      var list = [];
      if (state.mechanicParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniMechanicsList, ...response.results];
      }
      var noofpages = Math.ceil(
        response.count / state.mechanicParams?.page_size
      );
      return {
        ...state,
        miniMechanicsList: list,
        loading: false,
        mechanicParams: {
          ...state.mechanicParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniMechanicParams: (state, action) => {
      return {
        ...state,
        mechanicParams: action.payload,
      };
    },
    getMiniVehicleEstimation: (state) => {
      return {
        ...state,
        miniVehicleEstimationList: [],
        loading: true,
      };
    },
    miniVehicleEstimationSuccessful: (state, action) => {
      return {
        ...state,
        miniVehicleEstimationList: action.payload,
        loading: false,
      };
    },
    // getMiniEMIdates: (state) => {
    //   return {
    //     ...state,
    //     miniEMIdatesList: [],
    //     loading: true,
    //   };
    // },
    // miniEMIdatesSuccessful: (state, action) => {

    //   return {
    //     ...state,
    //     miniEMIdatesList: action.payload,
    //     loading: false,
    //   };
    // },
    getMiniInsurance: (state) => {
      return {
        ...state,
        miniInsuranceList: [],
        loading: true,
      };
    },
    miniInsuranceSuccessful: (state, action) => {
      return {
        ...state,
        miniInsuranceList: action.payload,
        loading: false,
      };
    },
    getMiniShowroom: (state) => {
      return {
        ...state,
        miniShowroomList: [],
        loading: true,
      };
    },
    miniShowroomSuccessful: (state, action) => {
      return {
        ...state,
        miniShowroomList: action.payload,
        loading: false,
      };
    },
    getMiniVehicletypes: (state) => {
      return {
        ...state,
        miniVehicletypesList: [],
        loading: true,
      };
    },
    miniVehicletypesSuccessful: (state, action) => {
      return {
        ...state,
        miniVehicletypesList: action.payload,
        loading: false,
      };
    },
    getMiniVehicletypesClearData: (state) => {
      return {
        ...state,
        miniVehicletypesList: [],
        loading: true,
      };
    },
    getMiniTenures: (state) => {
      return {
        ...state,
        miniTenuresList: [],
        loading: true,
      };
    },
    miniTenuresSuccessful: (state, action) => {
      action.payload = action.payload.sort((a, b) =>
        a.sequence > b.sequence ? 1 : -1
      );
      return {
        ...state,
        miniTenuresList: action.payload,
        loading: false,
      };
    },
    getSaleQuotationStockList: (state, action) => {
      if (state.saleQuotationStockListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          saleQuotationStockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    saleQuotationStockListSuccessful: (state, action) => {
      var response = action.payload.response;
      var list = [];
      if (state.saleQuotationStockListParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.saleQuotationStockList, ...response.results];
      }
      var noofpages = Math.ceil(
        response.count / state.saleQuotationStockListParams?.page_size
      );
      return {
        ...state,
        saleQuotationStockList: list,
        loading: false,
        saleQuotationStockListParams: {
          ...state.saleQuotationStockListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setSaleQuotationStockListParams: (state, action) => {
      return {
        ...state,
        saleQuotationStockListParams: action.payload,
      };
    },
    getMiniManagers: (state) => {
      return {
        ...state,
        miniManagersList: [],
        loading: true,
      };
    },
    miniManagersSuccessful: (state, action) => {
      return {
        ...state,
        miniManagersList: action.payload,
        loading: false,
      };
    },
    getFVRequestUsers: (state) => {
      if (state.FVRequestUsersParams?.page == 1) {
        return {
          ...state,
          loading: true,
          FVRequestUsersList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    FVRequestUsersSuccessful: (state, action) => {
      console.log("FVRequestUsersSuccessful", action.payload);
      var list = [];
      if (state.FVRequestUsersParams?.page == 1) {
        list = action.payload.response.results;
      } else {
        list = [
          ...state.FVRequestUsersList,
          ...action.payload.response.results,
        ];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.FVRequestUsersParams?.page_size
      );
      return {
        ...state,
        FVRequestUsersList: list,
        loading: false,
        FVRequestUsersParams: {
          ...state.FVRequestUsersParams,
          no_of_pages: noofpages,
        },
      };
    },
    setFVRequestUsersParams: (state, action) => {
      return {
        ...state,
        FVRequestUsersParams: action.payload,
      };
    },
    getMiniRepairTypes: (state) => {
      return {
        ...state,
        miniRepairTypesList: [],
        loading: true,
      };
    },
    miniRepairTypSuccessful: (state, action) => {
      return {
        ...state,
        miniRepairTypesList: action.payload,
        loading: false,
      };
    },
    getMiniLeaveReason: (state) => {
      return {
        ...state,
        miniLeaveReasonList: [],
        loading: true,
      };
    },
    miniLeaveReasonSuccessful: (state, action) => {
      return {
        ...state,
        miniLeaveReasonList: action.payload,
        loading: false,
      };
    },
    getMiniUsersList: (state) => {
      if (state.miniUsersListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniUsersList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniUsersListSuccessful: (state, action) => {
      var list = [];
      if (state.miniUsersListParams?.page == 1) {
        list = action.payload.results;
      } else {
        list = [...state.miniUsersList, ...action.payload.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniUsersListParams?.page_size
      );
      return {
        ...state,
        miniUsersList: list,
        loading: false,
        miniUsersListParams: {
          ...state.miniUsersListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniUsersListParams: (state, action) => {
      return {
        ...state,
        miniUsersListParams: action.payload,
      };
    },

    getMiniReportingToUsers: (state) => {
      if (state.miniReportingToUsersParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniReportingToUsersList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniReportingToUserSuccessful: (state, action) => {
      var list = [];
      if (state.miniReportingToUsersParams?.page == 1) {
        list = action.payload.results;
      } else {
        list = [...state.miniReportingToUsersList, ...action.payload.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniReportingToUsersParams?.page_size
      );
      return {
        ...state,
        miniReportingToUsersList: list,
        loading: false,
        miniReportingToUsersParams: {
          ...state.miniReportingToUsersParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniReportingToUsersParams: (state, action) => {
      return {
        ...state,
        miniReportingToUsersParams: action.payload,
      };
    },

    getMiniAssetList: (state) => {
      if (state.miniAssetParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniAssetList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniAssetListSuccessful: (state, action) => {
      var list = [];
      if (state.miniAssetParams?.page == 1) {
        list = action.payload.results;
      } else {
        list = [...state.miniAssetList, ...action.payload.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniAssetParams?.page_size
      );
      return {
        ...state,
        miniAssetList: list,
        loading: false,
        miniAssetParams: {
          ...state.miniAssetParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniAssetParams: (state, action) => {
      return {
        ...state,
        miniAssetParams: action.payload,
      };
    },

    getFinanceOVFList: (state) => {
      if (state.financeOVFParams?.page == 1) {
        return {
          ...state,
          loading: true,
          financeOVFList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    financeOVFListSuccessful: (state, action) => {
      var list = [];
      if (state.financeOVFParams?.page == 1) {
        list = action.payload.results;
      } else {
        list = [...state.financeOVFList, ...action.payload.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.financeOVFParams?.page_size
      );
      return {
        ...state,
        financeOVFList: list,
        loading: false,
        financeOVFParams: {
          ...state.financeOVFParams,
          no_of_pages: noofpages,
        },
      };
    },
    seFinanceOVFParams: (state, action) => {
      return {
        ...state,
        financeOVFParams: action.payload,
      };
    },

    setMiniVehicleParams:(state,action) =>{
      return{
        ...state,
        miniVehiclesParams:action.payload
      }
    },

    // Pending Insurance List
    getInsurancePendingList: (state) => {
      // if (state.insurancePendingParams?.page == 1) {
      //   return {
      //     ...state,
      //     loading: true,
      //     insurancePendingList: [],
      //   };
      // } else {
      //   return {
      //     ...state,
      //     loading: true,
      //   };
      // }
      return {
        ...state,
        loading: true,
        insurancePendingList: [],
      };
    },
    insurancePendingListSuccessful: (state, action) => {
      return {
        ...state,
        insurancePendingList: action.payload,
        loading: false,
      };
      // var response = action.payload;
      // var list = [];
      // if (state.insurancePendingParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.insurancePendingList, ...response.results];
      // }
      // var noofpages = Math.ceil(
      //   response.count / state.insurancePendingParams?.page_size
      // );
      // return {
      //   ...state,
      //   insurancePendingList: list,
      //   loading: false,
      //   insurancePendingParams: {
      //     ...state.insurancePendingParams,
      //     no_of_pages: noofpages,
      //   },
      // };
    },
    setInsurancePendingParams: (state, action) => {
      return {
        ...state,
        insurancePendingParams: action.payload,
      };
    },

    // Repair amount add purchase drop down
    getPurchaseRepairStock: (state, action) => {
      if (state.purchaseRepairStockParams?.page == 1) {
        return {
          ...state,
          loading: true,
          purchaseRepairStockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    purchaseRepairStockSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.purchaseRepairStockParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.purchaseRepairStockList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.purchaseRepairStockParams?.page_size
      );
      return {
        ...state,
        purchaseRepairStockList: list,
        loading: false,
        purchaseRepairStockParams: {
          ...state.purchaseRepairStockParams,
          no_of_pages: noofpages,
        },
      };
    },
    setPurchaseRepairStockParams: (state, action) => {
      return {
        ...state,
        purchaseRepairStockParams: action.payload,
      };
    },

    // refurbished amount add purchase drop down
    getPurchaseRefurbished: (state, action) => {
      if (state.purchaseRefurbishedParams?.page == 1) {
        return {
          ...state,
          loading: true,
          purchaseRefurbishedkList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    purchaseRefurbishedSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.purchaseRefurbishedParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.purchaseRefurbishedkList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.purchaseRefurbishedParams?.page_size
      );
      return {
        ...state,
        purchaseRefurbishedkList: list,
        loading: false,
        purchaseRefurbishedParams: {
          ...state.purchaseRefurbishedParams,
          no_of_pages: noofpages,
        },
      };
    },
    setPurchaseRefurbishedParams: (state, action) => {
      return {
        ...state,
        purchaseRefurbishedParams: action.payload,
      };
    },

    getMiniServiceMech: (state, action) => {
      if (state.serviceMechanicParams?.page == 1) {
        return {
          ...state,
          miniMechLoading: true,
          miniServiceMechanicList: [],
        };
      } else {
        return {
          ...state,
          miniMechLoading: true,
        };
      }
    },
    serviceMechanicSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.serviceMechanicParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniServiceMechanicList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.serviceMechanicParams?.page_size
      );
      return {
        ...state,
        miniServiceMechanicList: list,
        miniMechLoading: false,
        serviceMechanicParams: {
          ...state.serviceMechanicParams,
          no_of_pages: noofpages,
        },
      };
    },
    setServiceMechParams: (state, action) => {
      return {
        ...state,
        serviceMechanicParams: action.payload,
      };
    },


    getMiniRampList: (state, action) => {
      if (state.miniRampParams?.page == 1) {
        return {
          ...state,
          miniRampLoading: true,
          miniRampList: [],
        };
      } else {
        return {
          ...state,
          miniRampLoading: true,
        };
      }
    },
    miniRampListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniRampParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniRampList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniRampParams?.page_size
      );
      return {
        ...state,
        miniRampList: list,
        miniRampLoading: false,
        miniRampParams: {
          ...state.miniRampParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniRampParams: (state, action) => {
      return {
        ...state,
        miniRampParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUserPermissions,
  userPermissionsSuccessful,
  getMiniTwoVehicles,
  getMinicountry,
  getMiniGodown,
  minicountrySuccessful,
  getMiniStates,
  miniStatesSuccessful,
  getMiniStatesClearData,
  miniSeizedVehiclesSuccessful,
  getMiniCity,
  miniCitySuccessful,
  getMiniCityClearData,
  getMiniDistrict,
  MiniDistrictSuccessful,
  getMiniDistrictClearData,
  getMiniArea,
  miniAreaSuccessful,
  getMiniAreaClearData,
  getMiniDepartment,
  miniDepartmentSuccessful,
  getMiniFirm,
  miniFirmSuccessful,
  getMiniVehicles,
  getMiniCollectionExecutive,
  miniCollectionExecutiveSuccessful,
  miniVehiclesSuccessful,
  miniGodownSuccessful,
  setMiniVehiclesParams,
  getMiniVehiclesNonStock,
  miniVehiclesNonStockSuccessful,
  setMiniVehiclesNonStockParams,
  getPurchaseAvailableStock,
  purchaseAvailableStockSuccessful,
  setPurchaseAvailableStockParams,
  getMiniCounters,
  miniCountersSuccessful,
  getMiniCountersClearData,
  getAllCounters,
  allCountersSuccessful,
  getMiniZones,
  miniZonesSuccessful,
  getMiniZonesClearData,
  getMiniClusters,
  miniClustersSuccessful,
  getMiniClustersClearData,
  getMiniDivisions,
  miniDivisionsSuccessful,
  getMiniDivisionsClearData,
  getMiniBanks,
  miniBanksSuccessful,
  getMiniOccupation,
  miniOccupationSuccessful,
  getMiniBrands,
  miniBrandsSuccessful,
  getMiniBrandsClearData,
  getMiniBrandFamily,
  miniBrandFamilySuccessful,
  getMiniBrandFamilyClearData,
  getMiniColors,
  miniColorsSuccessful,
  getVersionColors,
  versionColorsSuccessful,
  getMiniFinanciers,
  miniFinanciersSuccessful,
  getMiniMechanics,
  miniMechanicListSuccessful,
  setMiniMechanicParams,
  getMiniAddressProof,
  miniAddressProofSuccessful,
  getMiniEmissionnorms,
  miniEmissionnormsSuccessful,
  getMiniPriority,
  miniPrioritySuccessful,
  getMiniPriorityClearData,
  getMiniVersion,
  miniVersionSuccessful,
  getMiniVersionClearData,
  getMiniModels,
  miniModelsSuccessful,
  getMiniModelsClearData,
  getMiniPercentageInterests,
  miniPercentageInterestsSuccessful,
  getMiniRejectReasons,
  miniRejectReasonsSuccessful,
  getMiniPurchaseEnquiry,
  miniPurchaseEnquirySuccessful,
  setMiniPurchaseEnquiryParams,
  getMiniSaleQuotation,
  miniSaleQuotationSuccessful,
  getMiniVehicleEstimation,
  miniVehicleEstimationSuccessful,
  // getMiniEMIdates,
  // miniEMIdatesSuccessful,
  getMiniInsurance,
  miniInsuranceSuccessful,
  getMiniShowroom,
  miniShowroomSuccessful,
  getMiniVehicletypes,
  miniVehicletypesSuccessful,
  getMiniVehicletypesClearData,
  getMiniTenures,
  miniTenuresSuccessful,
  getSaleQuotationStockList,
  saleQuotationStockListSuccessful,
  setSaleQuotationStockListParams,
  getMiniManagers,
  miniManagersSuccessful,
  getMinicountryClearData,
  getPurchaseStock,
  purchaseStockSuccessful,
  setPurchaseStockParams,
  getFVRequestUsers,
  FVRequestUsersSuccessful,
  setFVRequestUsersParams,
  getMiniRepairTypes,
  miniRepairTypSuccessful,
  clearPurchaseAvaliableStock,
  getMiniLeaveReason,
  miniLeaveReasonSuccessful,
  getMiniUsersList,
  miniUsersListSuccessful,
  setMiniUsersListParams,
  getMiniCustomers,
  miniCustomerListSuccessful,
  setMiniCustomerParams,
  getMiniReportingToUsers,
  miniReportingToUserSuccessful,
  setMiniReportingToUsersParams,
  getMiniAssetList,
  miniAssetListSuccessful,
  setMiniAssetParams,
  getFinanceOVFList,
  financeOVFListSuccessful,
  seFinanceOVFParams,
  setMiniVehicleParams,
  setMiniCollectionExecutiveParams,
  getInsurancePendingList,
  insurancePendingListSuccessful,
  setInsurancePendingParams,
  miniSeizedVehiclesParams,
  getPurchaseRepairStock,
  purchaseRepairStockSuccessful,
  setPurchaseRepairStockParams,
  setMiniSeizedVehiclesParams,
  getPurchaseRefurbished,
  setMiniGateInVehiclesParams,
  purchaseRefurbishedSuccessful,
  miniGateInVehiclesSuccessful,
  setPurchaseRefurbishedParams,
  getMiniServiceMech,
  serviceMechanicSuccessful,
  setServiceMechParams,
  getMiniRampList,
  miniRampListSuccessful,
  setMiniRampParams,
  apiError,
  setMiniGodownParams,
  getMiniSeizedVehicles,
  getMiniGateInVehicles,
  getMiniVehicleEstimationByVehicle,
  miniVehicleEstimationByVehicleSuccessful
  
} = miniSlice.actions;

export default miniSlice.reducer;
