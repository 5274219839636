import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getGateOutList,
  gateInListSuccessful,
  purchaseDocSuccessful,
  gateOutByIDSuccessful,
  vehicleDataSuccessful,
  purchasesImageUploadSuccessful,
  FormulaExecuterSuccessful,
  purchasesCheckApproveSuccessful,
  purchaseHistoryListSuccessful,
  saleHistoryListSuccessful,
  getPurchaseDoc,
  isRejectModelVisible,
  purchaseEnquirySuccessful,
  getPurchaseCheckApprove,
  repairAmountListSuccessful,
  reEstimationListSuccessful,
  InputChangeValue,
  addGateOutSuccessful,
  getGateInListByIdSuccessful,
} from "./gateOutSlice";
import { getVersionColors } from "../../mini/miniSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import { select } from "redux-saga/effects";
import { evaluate } from "mathjs";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
//If user is login then dispatch redux action's are directly from here.
function* GateOutList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      // if (k === "currentSort" || k === "sortOrder") {
      //   params.ordering = data.sortOrder + data.currentSort;
      // } else {
      if (data[k] != "" && k != null && k != "no_of_pages") {
        if (k == "start_date") {
          params[k] = moment(data[k]).format("YYYY-MM-DD");
        } else if (k == "end_date") {
          params[k] = moment(data[k]).format("YYYY-MM-DD");
        } else {
          params[k] = data[k];
        }
      }
      // }
    }
  }
  try {
    const response = yield call(getParamsList, "/godown/gate_out/list/", params);
    yield put(gateInListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* GateInListById({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      // if (k === "currentSort" || k === "sortOrder") {
      //   params.ordering = data.sortOrder + data.currentSort;
      // } else {
      if (data[k] != "" && k != null && k != "no_of_pages") {
        if (k == "start_date") {
          params[k] = moment(data[k]).format("YYYY-MM-DD");
        } else if (k == "end_date") {
          params[k] = moment(data[k]).format("YYYY-MM-DD");
        } else {
          params[k] = data[k];
        }
      }
      // }
    }
  }
  try {
    const response = yield call(getParamsList, `/godown/gate_in/detail/${payload.status_id}/`);
    yield put(getGateInListByIdSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* GateOutAdd({ payload }) {
  // var data = {};
  // for (const k in payload.purchaseObj) {
  //   if (Object.hasOwnProperty.call(payload.purchaseObj, k)) {
  //     if (payload.purchaseObj[k] !== null && payload.purchaseObj[k] !== "") {
  //       data[k] = payload.purchaseObj[k];
  //     }
  //   }
  // }
  try {
    if (payload.purchaseObj.status_id == 0) {
      const response = yield call(
        postEdit,
        `/godown/gate_out/detail/${payload?.purchaseObj?.id}/`,
        payload.purchaseObj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your GateOut Added Successfully.!
              </p>
            </div>
          ),
        });

        payload.history.push("/pages/gateout");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Purchase. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        `/godown/gate_out/detail/${payload.purchaseObj.id}/`,
        payload.purchaseObj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Purchase Edited Successfully.!
              </p>
            </div>
          ),
        });

        payload.history.push("/pages/gateout");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Purchase. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
    yield put(addGateOutSuccessful(null));
  } catch (error) {
    console.log("response", error.response);
    if(error.response.status === 400){
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    }else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
  
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
   
    yield put(addGateOutSuccessful(null));
    yield put(apiError(error));
  }
}

// Purchase History List
function* PurchaseHistory({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/byvehicleid/" + payload.id,
      {}
    );
    yield put(purchaseHistoryListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Sale History List

function* SaleHistory({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/byvehicleid/" + payload.id,
      {}
    );
    yield put(saleHistoryListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* purchaseEnquiry({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/enquiry/list/", data);
    yield put(purchaseEnquirySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* PurchaseApproval({ payload }) {
  try {
    const response = yield call(postAdd, "/purchase/approve/", payload);
    if (response.status === 200) {
      if (payload.approvalstatus == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your purchase has been approved successfully.
              </p>
            </div>
          ),
        });
      } else if (payload.approvalstatus == 3) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your purchase has been Rejected successfully.
              </p>
            </div>
          ),
        });
      }

      yield put(getPurchaseDoc(payload.purchase_id));
      yield put(getPurchaseCheckApprove({ purchase_id: payload.purchase_id }));
      yield put(isRejectModelVisible(false));
    } else {
      if (payload.approvalstatus == 2) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Approve Purchase. Please try again!
              </p>
            </div>
          ),
        });
      } else if (payload.approvalstatus == 3) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Reject Purchase. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* PurchaseCheckApprove({ payload }) {
  try {
    const response = yield call(postAdd, "/purchase/checkapprove/", payload);
    if (response.status === 200) {
      yield put(purchasesCheckApproveSuccessful(true));
    }
  } catch (error) {
    yield put(purchasesCheckApproveSuccessful(false));
    yield put(apiError(error));
  }
}

function* PurchaseDoc({ payload }) {
  try {
    const response = yield call(getList, "/purchase/" + payload, {});
    yield put(purchaseDocSuccessful(response));
    yield put(getVersionColors(response.vehicle?.version?.model?.id));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* GetGateOutByID({ payload }) {
  try {
    const response = yield call(getList, `/godown/gate_out/detail/${payload}/` , {});
    yield put(gateOutByIDSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* VehicleDataGet({ payload }) {
  try {
    const response = yield call(getList, "/masters/vehicles/" + payload, {});
    yield put(vehicleDataSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* FormulaExecuter({ payload }) {
  let systemFormulasList = yield select(
    (state) => state.system.systemFormulasList
  );

  try {
    var formulaObj = systemFormulasList?.find((formula) => {
      return formula?.code === payload.code;
    });

    if (formulaObj) {
      var result = evaluate(formulaObj.formula, payload.variables);

      if (result !== "NaN") {
        yield put(
          FormulaExecuterSuccessful({
            response: result,
            formulaCode: payload.code,
          })
        );
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to calculator. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
    // try {
    //   const response = yield call(postAdd, "/system/formulaexecuter/", payload);
    //   if (response.status === 200) {
    //     yield put(
    //       FormulaExecuterSuccessful({
    //         response: response.data,
    //         formulaCode: payload.code,
    //       })
    //     );
    //   } else {
    //     Modal.error({
    //       icon: (
    //         <span className="remix-icon">
    //           <RiAlertLine />
    //         </span>
    //       ),
    //       title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
    //       content: (
    //         <div>
    //           <p className="hp-p1-body hp-text-color-black-80">
    //             Sorry! Unable to calculator. Please try again!
    //           </p>
    //         </div>
    //       ),
    //     });
    //   }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* PurchasesImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      purchasesImageUploadSuccessful({ response, ImageType: payload.imageType })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}
function* GateInDelete({ payload }) {
  try {
    const response = yield call(postDelete, `/godown/gate_in/detail/${payload.id}/`);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Purchase has been deleted!
            </p>
          </div>
        ),
      });

      yield put(getGateOutList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Purchase has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// Repair Amount

function* RepairAmount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/repairamount/bypurchaseid/" + payload.id,
      {}
    );
    yield put(repairAmountListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Re Estimation

function* ReEstimation({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/reestimationamount/bypurchaseid/" + payload.id,
      {}
    );
    yield put(reEstimationListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* InsuranceValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/purchase/insurancenumber/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "insurancenumber",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response?.data?.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}


function* UpdateFsyncStatus({ payload }) {
  let purchaseParamsData = yield select(
    (state) => state.purchase.purchaseParams
  )
  try {
    const response = yield call(
      postEdit,
      "/purchase/reapprove/"  + payload.id, {
        approvalstatus: payload.approvalstatus,
      }
    );
    if (response.status === 200 ) {
      if( response.data.post_status == true){
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Selected Purchase is successfully synchronised into focus
              </p>
            </div>
          ),
        });
      }else{
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! selected Purchase is not posted on focus. please try
                again!
              </p>
            </div>
          ),
        });
      }
      yield put(getGateOutList(purchaseParamsData));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected Purchase is not posted on focus. please try
              again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetGateOutList() {
  yield takeEvery("gateOut/getGateOutList", GateOutList);
}
export function* watchGetGetGateInListById() {
  yield takeEvery("gateOut/getGateInListById", GateInListById);
}
export function* watchAddGateOut() {
  yield takeEvery("gateOut/addGateOut", GateOutAdd);
}
export function* watchGetPurchaseDoc() {
  yield takeEvery("gateOut/getPurchaseDoc", PurchaseDoc);
}
export function* watchGetGateOutByID() {
  yield takeEvery("gateOut/getGateOutByID", GetGateOutByID);
}
export function* watchGetVehicleData() {
  yield takeEvery("gateOut/getVehicleData", VehicleDataGet);
}
export function* watchGetFormulaExecuter() {
  yield takeEvery("gateOut/getFormulaExecuter", FormulaExecuter);
}
export function* watchGetPurchasesImageUpload() {
  yield takeEvery("gateOut/getPurchasesImageUpload", PurchasesImageUpload);
}
export function* watchPurchaseApproval() {
  yield takeEvery("gateOut/purchaseApproval", PurchaseApproval);
}
export function* watchGetPurchaseCheckApprove() {
  yield takeEvery("gateOut/getPurchaseCheckApprove", PurchaseCheckApprove);
}

export function* watchGetPurchaseHistory() {
  yield takeEvery("gateOut/getPurchaseHistory", PurchaseHistory);
}

export function* watchGetSaleHistory() {
  yield takeEvery("gateOut/getSaleHistory", SaleHistory);
}
export function* watchGetPurchaseEnquiry() {
  yield takeEvery("gateOut/getPurchaseEnquiry", purchaseEnquiry);
}
export function* watchGetGateInDelete() {
  yield takeEvery("gateOut/gateInDelete", GateInDelete);
}
export function* watchGetRepairAmount() {
  yield takeEvery("gateOut/getRepairAmount", RepairAmount);
}
export function* watchGetReEstimation() {
  yield takeEvery("gateOut/getReEstimation", ReEstimation);
}
export function* watchInsuranceNumberValidate() {
  yield takeEvery("gateOut/insuranceNumberValidate", InsuranceValidate);
}
export function* watchFsyncStatusUpdate() {
  yield takeEvery("gateOut/fsyncStatusUpdate", UpdateFsyncStatus);
}
function* gateOutSaga() {
  yield all([
    fork(watchGetGateOutList),
    fork(watchGetGetGateInListById),
    fork(watchAddGateOut),
    fork(watchGetPurchaseDoc),
    fork(watchGetGateOutByID),
    fork(watchGetVehicleData),
    fork(watchGetPurchasesImageUpload),
    fork(watchGetFormulaExecuter),
    fork(watchPurchaseApproval),
    fork(watchGetPurchaseCheckApprove),
    fork(watchGetPurchaseHistory),
    fork(watchGetSaleHistory),
    fork(watchGetPurchaseEnquiry),
    fork(watchGetGateInDelete),
    fork(watchGetRepairAmount),
    fork(watchGetReEstimation),
    fork(watchInsuranceNumberValidate),
    fork(watchFsyncStatusUpdate),
  ]);
}

export default gateOutSaga;
